import React, { useState, useEffect } from 'react';
import './App.css';
import './covid.css';
import {thousandCommas} from './util.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert'
import {Container, Row, Col, Table, Dropdown, Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import Papa from 'papaparse';
import dayjs from 'dayjs';
import Loader from 'react-loader-spinner'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';


var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const paramStr = (query, isPath) => {
    const encoder = (k, v) => isPath
        ? encodeURIComponent(`${k}=${v.replace(" ", "+")}`)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
    return Object.keys(query).sort()
        .map((k) => encoder(k, query[k]))
        .join(isPath ? '/' : '&')
}

const decodeCsv = (resp, resolve) => {
    resp.body.getReader().read().then(result => {
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        console.log(csv)
        const parsed = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
        resolve(parsed);
    })
}

const s3BucketUrl = "https://zjup-cov-data.s3.amazonaws.com"

function getDataFile(query) {
    const todayDate = dayjs.utc().format("YYYY-MM-DD")
    const s3DataUrl = `${s3BucketUrl}/query-results/${todayDate}/${paramStr(query, true)}/data.csv`;
    console.log(s3DataUrl)
//    const s3DataUrl = "https://zjup-cov-data.s3.amazonaws.com/query-results/2020-05-12/orderby%3Dcounty/state%3DCalifornia/data.csv"
    const apiBaseUrl = "https://6nst1w8ydf.execute-api.us-east-1.amazonaws.com/dev/?";
    const apiDataUrl = apiBaseUrl + paramStr(query, false);

    return new Promise((resolve, reject) => {
        try {
            fetch(s3DataUrl).then(resp => {
                if (resp.status === 200) {
                    decodeCsv(resp, resolve)
                } else {
                    fetch(apiDataUrl).then(output => {
                        console.log("FETCHNG API " + apiDataUrl)
                        if (output.status === 200) {
                            fetch(s3DataUrl, {cache: "no-store"}).then(resp => {
                                if (resp.status === 200) {
                                    decodeCsv(resp, resolve)
                                } else {
                                    reject("Something went wrong.")
                                }
                            })
                        } else {
                            reject("API responded with bad code.")
                        }
                    })
                }
            })
        } catch (e) {
            reject("An error occurred calling the data file creation service.");
        }
    });
}
function getMetadata() {
  return new Promise((resolve, reject) => {
    fetch(s3BucketUrl + "/nyt_metadata.json").then(resp => {
      console.log(resp)
      resp.json().then(json => {
        console.log(json)
        resolve(json)
      })
    })
  })
}

const format = {
  state: ["text", "State"],
  county: ["text", "County"],
  pop: ["int", "Population"],
  cases: ["int", "Total Cases"],
  cases_d1: ["int", "New Cases Today"],
  cases_d1r: ["dec1", "New Case Rate Today"],
  cases_d5r: ["dec1", "New Case Rate 5-day"],
  cases_d10r: ["dec1", "New Case Rate 10-day"],
  cases_10: ['int', "Cases as of 10 days ago"],
  cases_d5: ['int', "New cases (5-day)"],
  cases_d10: ['int', "New cases (10-day)"],
  deaths: ["int", "Total Deaths"],
  deaths_d1: ["int", "New Deaths Today"],
  deaths_d1r: ["dec1", "New Death Rate Today"],
  deaths_d5r: ["dec1", "New Death Rate 5-day"],
  deaths_d10r: ["dec1", "New Death Rate 10-day"],
}

const Cell = ({row, col, header=false}) => {
  const cellFormat = {
    text: <td>{row[col]}</td>,
    int: <td className="align-right">{thousandCommas(row[col], 0)}</td>,
    dec1: <td className="align-right">{thousandCommas(row[col], 1)}</td>,
    header: <th>{format[col][1]}</th>
  }
  return ( header 
    ? cellFormat['header']
    : cellFormat[format[col][0]])
}

function App() {
  const [tData, setTData] = useState({data:[]});
  const [metadata, setMetadata] = useState({metadata:{}})
  const [query, setQuery] = useState({
    orderby:"cases_d5r_desc", 
    datetime: dayjs().add(-2, 'day').format("YYYY-MM-DD")
  })
  const [loading, setLoading] = useState({loading: true})

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    console.log("Effect: metadata")
    
    getMetadata().then(ob => {
      ob.states = [...(new Set(ob.counties.map(x => x[0])))]
      setMetadata(ob)
      const newquery = {...query,
        datetime: ob.date_max
      }
      console.log("SETTING QUERY")
      console.log(newquery)
      setQuery(newquery)
      })

  }, []);
  useEffect(() => {
    console.log("Effect: query")
    console.log(query)
    setLoading(true)
      getDataFile(query).then(output => {
        console.log("UseEffect")
        console.log(output)
        setTData(output)
        setLoading(false)
      })
  }, [query])
  const tableRows = [
    "state", "county", "pop", "cases", "deaths", "cases_d1", "cases_d5", "cases_d1r", "cases_d5r", "cases_d10", "cases_d10r", "deaths_d1", "deaths_d10r"
  ]
  // console.log("META")
  // console.log(metadata)
  return (
  <div>
<Navbar expand="lg">
  <Navbar.Brand href="#home">Covid-19 Analysis</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="#home">Home</Nav.Link>
      <Nav.Link href="#link">Link</Nav.Link>
    </Nav>
    <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-success">Search</Button>
    </Form>
  </Navbar.Collapse>
</Navbar>
<Container>
<h1>Covid-19 Hotspot Analysis</h1>
<h3>U.S. by County</h3>
<Row>
<Col>
<Row>
  <Col>
<Dropdown>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    Sort By {query.orderby ? format[query.orderby.replace("_desc","")][1] : format["cases_d5r"][1]}
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {Object.keys(format).map( col => (
      <Dropdown.Item 
        href={`#/${col}`}
        onSelect={(k, e) => {
          console.log("HI" + col)
          setQuery({...query,
            orderby: (col + (format[col][0] == "text" ? "" : "_desc"))
          })}
        }
      >{format[col][1]}</Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>
</Col>
<Col>

<label className="table-control">States: </label>
<Dropdown>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    {query.state ? query.state: "All"}
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {metadata.states
     && metadata.states.map( state => (
      <Dropdown.Item 
        href={`#/${state}`}
        onSelect={(k, e) => {
          console.log("HI" + state)
          const newquery = {...query}
          newquery.state = state
          setQuery(newquery)}
        }
      >{state}</Dropdown.Item>
    ))
  }
  </Dropdown.Menu>
</Dropdown>
</Col>
<label className="table-control">Date: </label>
<div>
    <DayPickerInput 
    format={"yyyy-mm-dd"}
    value={query.datetime}
    min={metadata.date_min}
    max={metadata.date_max}
    onDayChange={(day) => {
      const fmtDay = dayjs(day).format('YYYY-MM-DD')
      console.log(fmtDay)
      const newquery = {...query}
      newquery.datetime = fmtDay
      setQuery(newquery)
    }}
  />
</div>
</Row>
{loading 
?<div className="loader">
<Loader
    type="Oval"
    color="#0088EE"
    height="100"
    width="100"
/>
</div>
:<div className="data-table"><Table striped bordered hover size="sm">
  <thead>
    <tr>
      {tableRows.map(column => <Cell row={{}} col={column} header={true}/>)}
    </tr>
  </thead>
  <tbody>
    {tData.data.map(x => (
        <tr>
          {tableRows.map(column => <Cell row={x} col={column} header={false}/>)}
        </tr>
    ))}
  </tbody>
</Table>
</div>
}
</Col>
</Row>
</Container>
<footer>
  <Row><Col>
  <div>
  Data from <a href="https://github.com/nytimes/covid-19-data">The New York Times Covid-19 data repository</a>
  </div>
  <div>
    Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> 
  </div>
  </Col></Row>
</footer>
</div>
  );
}

export default App;
